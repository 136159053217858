
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from "next"
import useTranslation from "next-translate/useTranslation"
import React from "react"

import { BlogContent } from "@app/components/blog"
import { Seo } from "@app/components/seo"
import { Layout } from "@app/shared/components/layout"
import { PageKind } from "@app/shared/types/pages"
import { fetchPosts } from "@app/shared/utils/blog/wordpress-blog"
import * as casavoPages from "@shared/utils/pages"

import { PaginatedCategory } from "./category/[tag]"

const PAGE_SIZE = 9

type IndexPostsProps = Omit<PaginatedCategory, "meta" | "tag">

const Blog = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { t } = useTranslation()
  return (
    <>
      <Seo
        alternates={props.seo.alternates}
        canonical={props.seo.canonical}
        description={t("blog:meta.description")}
        og={{
          description: t("blog:meta.description"),
          images: [
            { url: (process.env.NEXT_PUBLIC_IMAGE_PATH || "").concat("/images/seo/casavo-og-image.png") },
          ],
          title: t("blog:meta.title"),
          type: "website",
          url: props.seo.canonical,
        }}
        title={t("blog:meta.title")}
      />
      <Layout>
        <BlogContent posts={props.posts} />
      </Layout>
    </>
  )
}

const wrappedGetStaticProps: GetStaticProps<IndexPostsProps> = async (ctx: GetStaticPropsContext) => {
  if (ctx.locale === casavoPages.DEFAULT_LOCALE) {
    return {
      notFound: true,
      props: {} as never,
    }
  }

  const locales = casavoPages.countryLocales(ctx.locales as Locale[])
  const seoBuilder = casavoPages.seo(process.env.NEXT_PUBLIC_BASE_PATH as string)(PageKind.blog)

  const seo = {
    alternates: seoBuilder.alternates(locales).map((url) => ({ ...url, href: url.href })),
    canonical: seoBuilder.canonical(ctx.locale as Locale),
  }

  if (!process.env.NEXT_PUBLIC_WORDPRESS_BLOG) {
    return { props: { error: "NOKEYS" } as never }
  }
  try {
    const response = await fetchPosts(1, PAGE_SIZE, ctx.locale as Locale)
    const posts = response.items
    return {
      props: {
        posts,
        seo,
      },
      revalidate: 3600, // 60 minutes, in seconds
      // revalidate: 900, // 15 minutes for testing purposes
    }
  } catch (e) {
    console.error(e)
  }

  return {
    props: {} as never,
  }
}

 const _getStaticProps: GetStaticProps = wrappedGetStaticProps

export default Blog


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/blog/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  