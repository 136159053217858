import React from "react"

import { HeroTitle } from "@app/shared/components/HeroTitle"
import { MyImage } from "@app/shared/components/MyImage"
import { imageFitContain } from "@app/shared/design-lib/styles.css"

import { container, wideOnly } from "./styles/index.css"

interface Props {
  alt: string
  mainTitle: string
  secondaryTitle: string
}

export const HeroTitleBlog: React.FC<Props> = ({ alt, mainTitle, secondaryTitle }) => {
  return (
    <div className={container}>
      <HeroTitle subtitle={secondaryTitle} title={mainTitle} />
      <div className={wideOnly}>
        <MyImage
          alt={alt}
          className={imageFitContain}
          height={260}
          src={`/images/blog/illustrations/inside-scene.svg`}
          width={630}
        />
      </div>
    </div>
  )
}
