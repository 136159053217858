import Link from "next/link"
import React from "react"

import { handleUserEventClick } from "../utils/tracking/userEvent"

import { textCta } from "./TextCta.css"
import { TypographyOld } from "./TypographyOld"

interface TextCtaProps {
  eventLabel?: string
  label: string
  url: string
}

export const TextCta: React.FC<TextCtaProps> = ({ eventLabel, label, url }) => {
  return (
    <Link
      passHref
      aria-label={label}
      className={textCta}
      href={url}
      onClick={typeof eventLabel !== "undefined" ? () => handleUserEventClick(eventLabel) : undefined}
    >
      <TypographyOld color="inherit" variant="bodyL_strong">
        {label}
      </TypographyOld>
    </Link>
  )
}
