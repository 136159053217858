import useTranslation from "next-translate/useTranslation"
import React from "react"

import { TypographyOld } from "@app/shared/components/TypographyOld"

import { firstTitle, fitContent, h4, heroTitle, secondTitle } from "./HeroTitle.css"

interface HeroTitleProps {
  noMargin?: boolean
  subtitle: string
  title: string
}

export const HeroTitle: React.FC<HeroTitleProps> = ({ noMargin, subtitle, title }) => {
  const title2 = title.split("<br>")
  const { lang } = useTranslation()

  return (
    <section className={heroTitle({ noMargin })}>
      <div className={fitContent}>
        <TypographyOld
          className={firstTitle({ noMargin, size: lang === "en" || noMargin })}
          component="h1"
          variant="heroTitle"
        >
          {title2[0]}
        </TypographyOld>
        <TypographyOld
          className={secondTitle({ size: lang === "en" || noMargin })}
          component="h1"
          variant="heroTitle"
        >
          {title2[1]}
        </TypographyOld>
      </div>
      <TypographyOld className={h4} component="h4" variant="h4">
        {subtitle}
      </TypographyOld>
    </section>
  )
}
